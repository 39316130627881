<template>
  <div id="admin-service-request">
    <div class="title">Destek Talebi</div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Destek Talebi</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group">
        <div v-if="multipleSelection.length > 0">{{ multipleSelection.length }} Adet veri seçildi</div>
        <el-button v-if="multipleSelection.length > 0" @click="deleteItem">
          <i class="icon-delete"></i>
        </el-button>

        <el-tooltip class="item" effect="dark" content="Export" placement="top">
          <el-button class="import" @click="exportFile()"><i class="icon-export"></i></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="search-status">
      <div class="status">
        <div class="show">Göster</div>
        <el-select class="first" v-model="filter.PageSize" placeholder="Seçiniz...">
          <el-option v-for="(item, index) in pagesize" :key="index" :label="item" :value="item"> </el-option>
        </el-select>
      </div>

      <div class="search">
        <el-input style="width: 290px" placeholder="Arama..." v-model="filter.SearchTerm" clearable>
          <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
        </el-input>
      </div>
    </div>
    <div class="all-data">
      <div class="list-table">
        <el-table ref="multipleTable" :data="SupportRequestList" @selection-change="handleSelectionChange">
          <el-table-column sortable type="selection" align="center" style="text-center"> </el-table-column>

          <el-table-column sortable label="AD - SOYAD" stripe width="280">
            <template slot-scope="scope">
              <div>
                <div class="name-all">
                  <div class="name-short"
                    :class="[{ first: scope.$index % 4 == 0 }, { second: scope.$index % 4 == 1 }, { third: scope.$index % 4 == 2 }, { fourth: scope.$index % 4 == 3 }]">
                    {{ scope.row.User.FirstName[0] }}{{ scope.row.User.LastName[0] }}</div>
                  <div class="name-container">
                    <div class="name">
                      {{
                        scope.row.User.FirstName.trim()
                          .split(" ")
                          .map((x) => x[0] + "***")
                          .reduce((x, y) => x + " " + y, "")
                      }}
                      {{
                        scope.row.User.LastName.trim()
                          .split(" ")
                          .map((x) => x[0] + "***")
                          .reduce((x, y) => x + " " + y, "")
                      }}
                    </div>
                    <div style="color: #8697a8">{{ scope.row.User.Email }}</div>

                    <div class="mail">{{ scope.row.User.PhoneNumber }}</div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column sortable prop="User.Email" label="EMAİL" width="270"> </el-table-column> -->

          <el-table-column sortable prop="Title" label="TALEP"> </el-table-column>
          <el-table-column sortable prop="IpAddress" label="IP ADRESİ" width="180"> </el-table-column>
          <el-table-column sortable prop="Platform" label="PLATFORM" width="150">
            <template slot-scope="scope">
              {{ getEnumsDisplay("DevicePlatform", scope.row.Platform) }}
            </template>
          </el-table-column>
          <el-table-column sortable prop="CreatedDateTime" label="KAYIT TARİHİ" width="150">
            <template slot-scope="scope">{{ $moment(scope.row.CreatedDateTime).format("DD.MM.YYYY") }}</template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Kayıt bulunamadı.</div>
              <div class="no-data-text">Herhangi bir destek talebi bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>
      <div class="pagination" v-if="this.filter != null">
        <div class="info">{{ filter.PageSize * filter.Page - filter.PageSize }}-{{ filter.PageSize * filter.Page > result.TotalCount ? result.TotalCount
          : filter.PageSize * filter.Page }} {{ $localization('web-pagination-info-text') }} {{ result.TotalCount }}</div>
        <el-pagination background :current-page.sync="filter.Page" :page-size="filter.PageSize" layout="prev, pager, next"
          :total="result.TotalCount"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SupportRequest: null,

      pagesize: [10, 20, 30, 40],
    };
  },
  async beforeMount() {
    await this.getData();
  },
  computed: {
    multipleSelection() {
      return this.$store.getters.getSupportRequestMultipleSelection;
    },
    filter() {
      return this.$store.getters.getSupportRequestFilter;
    },
    SupportRequestList() {
      return this.$store.getters.getSupportRequestList;
    },
    result() {
      return this.$store.getters.getSupportRequestResult;
    },
  },
  methods: {
    exportFile() {
      var label = "DestekTalepleri.xlsx";
      this.$client
        .get("/SupportRequest/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    async getData() {
      await this.$store.dispatch("getSupportRequestList", this.filter);
    },
    handleSelectionChange(val) {
      this.$store.commit("setMultipleSelect", val);
    },
    async deleteItem() {
      await this.$store.dispatch("deleteSupportRequestItem", this.multipleSelection);
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        window.clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.getData();
        }, 400);
      },
    },
  },
};
</script>

<style lang="less">
#admin-service-request {
  padding: 42px 30px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }

  .title {
    display: flex;
    gap: 10px;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #44566c;
    padding-bottom: 29px;
  }

  .breadcrumb {
    .buttons-group {
      align-items: center;
      display: flex;
      gap: 24px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;

        i {
          background: #44566c;
        }
      }
    }
  }

  .search-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 42px;
    margin-bottom: 22px;

    .show {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #44566c;
      margin-right: 10px;
    }

    .status {
      display: flex;
      align-items: center;
      gap: 20px;

      .first {
        width: 75px;
      }

      .second {
        .el-date-editor--daterange.el-input__inner {
          width: 225px;
          height: 40px;
          align-items: center;
          filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
          border: none;
          border-radius: 5px;

          ::placeholder {
            color: #44566c;
            font-size: 15px;
            font-weight: 400;
          }

          i {
            line-height: unset;
          }

          .icon-calendar {
            padding: 0 15px;
            background-color: #44566c;
          }
        }
      }

      .third {
        width: 145px;
      }
    }

    .search {
      .el-input {
        width: 290px;
        height: 40px;

        input {
          padding-left: 40px;
          filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
          border: none;
          border-radius: 5px;
        }

        ::placeholder {
          color: #8697a8;
          font-size: 15px;
          font-weight: 400;
        }

        i {
          color: #8697a8;
          font-size: 15px;
          background-color: rgba(255, 255, 255, 0);
          padding: 0 10px;
        }
      }
    }
  }

  .el-input__inner {
    border: none;
    filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
  }

  .el-input__inner::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #44566c;
  }

  .el-input__suffix {
    .el-input__suffix-inner {
      .el-select__caret {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0px;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
        height: auto;

        .el-table__row {
          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            display: flex;
            align-items: center;
            gap: 10px;

            i {
              height: 40px;
            }
          }
        }

        .cell {
          .name-all {
            display: flex;
            gap: 10px;
            align-items: center;

            .name-short {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 40px;
              min-height: 40px;
              border: none;
              border-radius: 50%;
              font-size: 14px;
              font-weight: 500;
            }

            .name-short.first {
              background-color: #ff3d57;
              color: white;
            }

            .name-short.second {
              background-color: #fdbf5e;
              color: white;
            }

            .name-short.third {
              background-color: #22cce2;
              color: white;
            }

            .name-short.fourth {
              background-color: #ff8a48;
              color: white;
            }

            .name-container {
              .name {
                word-break: break-word;
                color: #44566c;
                font-weight: 400;
                font-size: 15px;
              }

              .mail {
                color: #8697a8;
                font-weight: 400;
                font-size: 13px;
              }
            }
          }
        }

        .three-dots {
          background-color: inherit;

          i {
            height: 25px;
            width: 25px;
          }
        }
      }
    }

    .el-dialog {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }

        input {
          background: #f8fafb;
          border: none;
        }

        .el-upload {
          width: 100%;
          border-color: #8697a8;
        }
      }

      .dialog-footer {
        width: 100%;
      }
    }
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      font-size: 15px;
      font-weight: 400;
      color: #8697a8;
    }

    .el-pagination {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
      background-color: white;
      border-radius: 8px;

      button {
        background-color: white;
        padding: 0;
        margin: 0;
        height: 100% !important;

        &.btn-prev {
          border-radius: 8px 0 0 8px;
          border-right: 1px solid #eaedf0;
        }

        &.btn-next {
          border-radius: 0 8px 8px 0;

          border-left: 1px solid #eaedf0;
        }
      }

      .el-pager {
        margin: 0 10px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: space-between;

        li {
          padding: 0;
          margin: 0;
          background-color: white;

          &.active {
            background-color: #54d0c8;
            border-radius: 8px;
            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}</style>
